import React from "react";
import Link from "next/link";
import Head from "next/head";

import styles from "../styles/error.scss";

const FourOFour = () => (
  <div className="rotoql-content-error__container">
    <Head>
      <title>Error</title>
      <meta name="robots" content="noindex,follow" />
      <meta name="googlebot" content="noindex,follow" />
    </Head>
    <div className="rotoql-content-error__text-container">
      <h1 className="rotoql-content-error__title">404</h1>
      <Link href="/" legacyBehavior>
        <a className="rotoql-content-error__link">Go Home</a>
      </Link>
    </div>
    <style jsx global>
      {styles}
    </style>
  </div>
);

export default FourOFour;
